

const dev = {
    frontendAPIUrl: "https://beta-api.codedesign.app",
    codedesignIconURL: "https://res.cloudinary.com/dbyioi2qq/q_auto/v1684998292/static/logo-cd-ai-bsvg_1684998291_61342.svg",
    frontendUrl: "https://staging-production-jqesv.codedesign.app",
    frontendAuthenticationUrl: "https://staging-accounts.codedesign.app",
    cache: true,
    env: "development",
  };
  

  const prod = {
    apiUrl: "https://production-api.codedesign.app/",
    authenticationUrl: "https://production-api.codedesign.app/",
    frontendAuthenticationUrl: "https://accounts.codedesign.ai",
    frontendUrl: "https://dev.codedesign.ai",
    frontendAPIUrl: "https://production-api.codedesign.app",
    codedesignIconURL: "https://res.cloudinary.com/dbyioi2qq/q_auto/v1684998292/static/logo-cd-ai-bsvg_1684998291_61342.svg",
    cloudinaryApiUrl: "https://res.cloudinary.com/storylens",
    CLOUDINARY_UPLOAD_PRESET: "storylens-preset",
    ClOUD_NAME: "storylens",
    CLOUDINARY_UPLOAD_URL: "https://api.cloudinary.com/v1_1/storylens/image/upload",
    cache: false,
    env: "production",
  };
       

  const getConfig = () => {
    const environment = process.env.REACT_APP_ENV;
  
    if (environment === "production") {
      return prod; 
    } else {
      return dev;
    }
  };
  
  // destructuring the config object
  export const environmentURLs = {...getConfig()};
  